import { gql } from "graphql-request"

export const blockPharmacyProjectGroup = gql`
mutation blockPharmacyProjectGroup($pharmacyId: ID!, $dbId: ID!, $projectId: ID!) {
    blockPharmacyProjectGroup(pharmacyId: $pharmacyId, dbId: $dbId, projectId: $projectId) {
      blockedPharmacyProjects {
        ... on KernPharmacyProject {
          dbId
          id
          externalId
        }
        ... on GskPharmacyProject {
          dbId
          id
          externalId
        }
        ... on BayerPharmacyProject {
          dbId
          id
          externalId
        }
        ... on PbPharmacyProject {
          dbId
          id
          externalId
        }
        ... on KlosterPharmacyProject {
          dbId
          id
          externalId
        }
        ... on ReckittPharmacyProject {
          dbId
          id
          externalId
        }
      }
    }
  }
`

export const unblockPharmacyProjectGroup = gql`
  mutation UnblockPharmacy($pharmacyId: ID!, $dbId: ID!, $projectId: ID!) {
    unblockPharmacy(
      pharmacyId: $pharmacyId, 
      dbId: $dbId,
      projectId: $projectId
    ){
      pharmacyId
    }
  }
`
